<template>
	<div>
		<ZyroLabel>
			{{ $t('builder.editForm.theme') }}
		</ZyroLabel>
		<div class="theme-control">
			<button
				v-for="theme in $options.THEMES"
				:key="theme"
				class="theme-control__card"
				:class="computedClasses(theme)"
				:title="`${$t('builder.editForm.theme')} ${theme}`"
				@click="changeTheme(theme)"
			>
				<label
					class="theme-control__label"
					:class="`theme-control__label--${theme}`"
				>
					{{ $t('builder.editForm.label') }}
				</label>
				<input
					class="theme-control__input"
					:class="`theme-control__input--${theme}`"
					placeholder="Form text"
					label="Label"
					disabled
				>
				<button
					class="theme-control__button"
					:class="`theme-control__button--${theme}`"
				>
					{{ $t('builder.editForm.submit') }}
				</button>
			</button>
		</div>
	</div>
</template>

<script>
import {
	THEMES,
	THEME_LIGHT,
} from '@user/components/grid-components/form/constants';

export default {
	THEMES,
	props: {
		selectedTheme: {
			type: String,
			validator: (theme) => THEMES.includes(theme),
			default: THEME_LIGHT,
		},
	},
	methods: {
		computedClasses(theme) {
			return [
				{ 'theme-control__card--active': theme === this.selectedTheme },
				`theme-control__card--${theme}`,
			];
		},
		changeTheme(theme) {
			this.$emit('change', theme);
		},
	},
};
</script>

<style lang="scss" scoped>
$border-color: var(--colors-grey-400);

.theme-control {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 8px;
	margin: 8px 0 15px;
	font-size: 14px;

	&__card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		height: 120px;
		padding: 13px 16px 17px 16px;
		cursor: pointer;
		border: 1px solid $border-color;
		border-radius: $border-radius-small;

		&--light {
			background: var(--colors-grey-100);
		}

		&--dark {
			background: var(--colors-grey-800);
		}

		&--active {
			border: 1px solid $accent-two;
		}
	}

	&__label {
		cursor: pointer;

		&--light {
			color: var(--colors-grey-800);
		}

		&--dark {
			color: var(--colors-light);
		}
	}

	&__input {
		width: 100%;
		height: 33px;
		cursor: pointer;
		border: none;

		&::placeholder {
			padding-left: 13px;
		}

		&--light {
			color: var(--colors-grey-800);
			background: var(--colors-grey-100);
			border: 1px solid $border-color;

			&::placeholder {
				color: var(--colors-grey-800);
			}
		}

		&--dark {
			background: rgba(var(--colors-light), 0.1);
			border: 1px solid var(--colors-light);

			&::placeholder {
				color: var(--colors-light);
			}
		}
	}

	&__button {
		height: 28px;
		padding: 6px 10px;
		margin-top: 8px;
		cursor: pointer;

		&--light {
			color: var(--colors-light);
			background-color: var(--colors-grey-600);
		}

		&--dark {
			color: var(--colors-dark);
			background-color: var(--colors-light);
		}
	}
}
</style>
